import { useState, useEffect, useMemo } from 'react'
import themeConfig from 'theme'
import { useBreakpoints } from '~/hooks/useBreakpoints'

export type TailwindBreakpoints = {
  [Property in keyof typeof themeConfig.theme.screens]: number
}

export interface CarouselColumnsConfig extends TailwindBreakpoints {
  base: number
}

export const useGetSlidesSetup = (customConfig?: CarouselColumnsConfig) => {
  const [scrollBehaviorPreference, setScrollBehaviorPreference] = useState<'smooth' | 'auto'>(
    'smooth',
  )
  const { currentBreakpoint } = useBreakpoints()

  const config = useMemo(() => {
    const baseConfig = { ...themeConfig.theme.carousel } as CarouselColumnsConfig

    if (customConfig) {
      if (typeof customConfig === 'number') {
        return Object.keys(baseConfig).reduce(
          (acc, bp) => {
            acc[bp as keyof CarouselColumnsConfig] = customConfig
            return acc
          },
          { ...baseConfig },
        )
      } else {
        return { ...baseConfig, ...customConfig }
      }
    }
    return baseConfig
  }, [customConfig])

  const cssConfig = useMemo(
    () =>
      Object.entries(config).reduce((acc, [name, value]) => {
        acc[`--carousel-slidesPerView-${name}`] = value.toString()

        return acc
      }, {} as Record<string, string>),
    [config],
  )

  const highestPossibleSpv = useMemo(() => {
    const values = Object.values(config)

    return Math.max(...values)
  }, [config])

  const slidesPerView = useMemo(() => {
    return currentBreakpoint ? config[currentBreakpoint.key] || 1 : config.base ?? 1
  }, [currentBreakpoint, config])

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-reduced-motion: no-preference)')

    setScrollBehaviorPreference(mediaQuery.matches ? 'smooth' : 'auto')

    const handler = (e: MediaQueryListEvent) => {
      setScrollBehaviorPreference(e.matches ? 'smooth' : 'auto')
    }

    mediaQuery.addEventListener('change', handler)
    return () => mediaQuery.removeEventListener('change', handler)
  }, [])

  return {
    config,
    cssConfig,
    slidesPerView,
    highestPossibleSpv,
    scrollBehaviorPreference,
  }
}
