import React, { ReactElement } from 'react'

export interface CustomProgressBarProps {
  currentSlide?: number
  progressPercentage: number
  handleClick: (event: React.MouseEvent<HTMLDivElement>) => void
}
interface CarouselProgressBarProps {
  progressPercentage: number
  className?: string
  disableNavigation?: boolean
  totalSlides: number
  slidesPerView: number
  onSlideChange?: (slideIndex: number) => void
  renderCustomProgressBar?: (props: {
    progressPercentage: number
    handleClick: (event: React.MouseEvent<HTMLDivElement>) => void
  }) => ReactElement | undefined
}

export const CarouselProgressBar: React.FC<CarouselProgressBarProps> = ({
  className = '',
  disableNavigation = false,
  onSlideChange,
  totalSlides,
  slidesPerView,
  renderCustomProgressBar,
  progressPercentage,
}): ReactElement => {
  const maxScrollPosition = Math.max(0, totalSlides - slidesPerView)

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (disableNavigation || !onSlideChange || maxScrollPosition === 0) return

    const rect = event.currentTarget.getBoundingClientRect()
    const x = event.clientX - rect.left
    const clickPercentage = x / rect.width

    const newScrollPosition = Math.round(clickPercentage * maxScrollPosition)
    const newSlideIndex = Math.min(newScrollPosition + 1, totalSlides - slidesPerView + 1)

    onSlideChange(newSlideIndex)
  }

  const customBar = renderCustomProgressBar?.({ progressPercentage, handleClick })

  if (customBar) {
    return customBar
  }

  return (
    <div className={className}>
      <div
        className={`mt-7.5 h-0.5 w-full rounded-full bg-sg-grey lg:mt-[50px] ${
          !disableNavigation && onSlideChange && maxScrollPosition > 0 ? 'cursor-pointer' : ''
        }`}
        role="progressbar"
        aria-valuenow={progressPercentage}
        aria-valuemin={0}
        aria-valuemax={100}
        onClick={handleClick}
      >
        <div
          className="h-full max-w-full rounded-full bg-primary-black transition-all duration-300 ease-in-out"
          style={{ width: `${progressPercentage}%` }}
        />
      </div>
    </div>
  )
}
