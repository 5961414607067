import { RecommendationsParameters } from '@constructor-io/constructorio-client-javascript'
import { useQuery } from '@tanstack/react-query'
import { useCioClient } from '~/hooks/useCioClient'

export const useCioRecommendations = (
  podId?: string | null,
  params?: RecommendationsParameters,
  enabled: boolean = true,
) => {
  const isCioEnabled = !!process.env.NEXT_PUBLIC_CONSTRUCTORIO_API_KEYS
  const cioClient = useCioClient()

  const { data, isLoading } = useQuery({
    queryKey: ['cioRecommendations', podId, params],
    queryFn: async () => {
      if (!cioClient || !podId) {
        return null
      }

      return cioClient?.recommendations.getRecommendations(podId, {
        numResults: 10,
        ...params,
        hiddenFields: [
          'parent_alternate_image',
          'parent_attribute_set_id',
          'parent_related_products_json',
          'size_id',
        ],
      })
    },
    enabled: isCioEnabled && !!podId && !!cioClient && enabled,
  })

  return {
    isEnabled: isCioEnabled && !!podId && !!cioClient,
    recommendations: data?.response.results,
    podId: data?.response.pod?.id,
    totalResultsFound: data?.response.total_num_results,
    resultId: data?.result_id,
    isLoading,
  }
}
