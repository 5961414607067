import clsx from 'clsx'
import ContentLoader from 'react-content-loader'
import { ClientOnly } from '~/elements/ClientOnly/ClientOnly'

type PropsCards = {
  className?: string
}

const ProductCard = ({ className }: PropsCards) => {
  return (
    <ClientOnly>
      <figure
        className={clsx(
          'relative h-full border-b border-r border-t border-grey-border border-opacity-50 pb-3 pt-8 lg:pb-8 lg:pt-14',
          className,
        )}
      >
        <ContentLoader
          speed={3.3}
          viewBox="0 0 360 646"
          backgroundColor="#F4F4F4"
          foregroundColor="#CACACA"
          gradientRatio={1}
        >
          <rect x="30" y="0" rx="0" ry="0" width="300" height="480" />
          <rect x="70" y="510" rx="0" ry="0" width="220" height="10" />
          <rect x="60" y="530" rx="0" ry="0" width="240" height="10" />
          <rect x="70" y="550" rx="0" ry="0" width="220" height="10" />
          <circle cx="100" cy="610" r="12" />
          <circle cx="140" cy="610" r="12" />
          <circle cx="180" cy="610" r="12" />
          <circle cx="220" cy="610" r="12" />
          <circle cx="260" cy="610" r="12" />
        </ContentLoader>
      </figure>
    </ClientOnly>
  )
}

export const LoadingItems = ({}) => {
  const loopDesktopLarge = [0, 1, 2, 3, 4, 5]
  const loop = [0, 1, 2, 3, 4]
  const loopMobile = [0, 1]

  return (
    <section className="content-width max-w-[1354px] overflow-hidden">
      <div className="hidden grid-flow-col lgx:grid">
        {loopDesktopLarge.map((item) => (
          <ProductCard key={item} />
        ))}
      </div>

      <div className="hidden grid-flow-col lg:grid lgx:hidden">
        {loop.map((item) => (
          <ProductCard key={item} />
        ))}
      </div>

      <div className="grid grid-flow-col lg:hidden">
        {loopMobile.map((item) => (
          <ProductCard key={item} />
        ))}
      </div>
    </section>
  )
}
