import clsx from 'clsx'
import { ReactElement, useCallback, useRef, useState } from 'react'
import {
  CarouselColumnsConfig,
  useGetSlidesSetup,
} from '~/modules/Carousel/hooks/useGetSlidesSetup'
import { Carousel, CarouselControls } from '~/modules/Carousel/components/Carousel'
import { SlideNav } from '~/elements/SlideNav/SlideNav'
import { useCarouselGap } from '~/modules/Carousel/hooks/useCarouselGap'
import { useTranslation } from 'next-i18next'
import { ButtonMain } from '~/elements/Button/ButtonMain'

interface SliderProps<T> {
  products: T[]
  headlineText?: string
  renderSlide: (product: any, index: number, isSlideVisible: boolean) => ReactElement
  gridConfig?: Partial<CarouselColumnsConfig> | number
  gap?: number
  useNativeScrollbar?: boolean
  nativeScrollbarClasses?: string
  scrollBy?: number
  headlineClasses?: string
  link?: string | null
}

export const ProductsSlider = <T,>({
  products,
  headlineText,
  renderSlide,
  gridConfig = {
    base: 2.3,
    xs: 2.3,
    sm: 3,
    md: 4,
    lg: 5,
    lgx: 4,
    xl: 5,
    '2xl': 5,
    '2k': 5,
    '4k': 5,
  },
  useNativeScrollbar,
  nativeScrollbarClasses,
  scrollBy = 1,
  headlineClasses,
  gap,
  link,
}: SliderProps<T>) => {
  const { t } = useTranslation()
  const carouselControlsRef = useRef<CarouselControls | null>(null)
  const { slidesPerView } = useGetSlidesSetup(gridConfig as CarouselColumnsConfig)
  const [isAtStart, setIsAtStart] = useState(true)
  const [isAtEnd, setIsAtEnd] = useState(false)
  const carouselGap = useCarouselGap(10, 20)
  const handlePrevClick = useCallback(() => {
    carouselControlsRef.current?.slideToPrev()
  }, [])

  const handleNextClick = useCallback(() => {
    carouselControlsRef.current?.slideToNext()
  }, [])

  const handleNavigationStateChange = useCallback((atStart: boolean, atEnd: boolean) => {
    setIsAtStart(atStart)
    setIsAtEnd(atEnd)
  }, [])

  if (!products || !products.length) return null

  return (
    <section className="content-width relative box-border overflow-hidden">
      <div
        className={clsx(
          'mb-[30px] flex items-center',
          headlineText ? 'justify-between' : 'justify-end',
          headlineClasses,
        )}
      >
        {headlineText ? (
          <h2 className="text-20-25-sg font-extralight lgx:text-30-35-sg">{headlineText}</h2>
        ) : null}
        <div className="flex items-center gap-10">
          {link && (
            <ButtonMain variant="Text" href={link}>
              {t('Show more')}
            </ButtonMain>
          )}
          {slidesPerView < products.length && (
            <SlideNav
              clickNext={handleNextClick}
              clickPrev={handlePrevClick}
              disableNext={isAtEnd}
              disablePrev={isAtStart}
              className="hidden lg:flex"
            />
          )}
        </div>
      </div>
      <Carousel
        progressbarClassName="pr-5 lg:pr-0"
        gridConfig={gridConfig}
        gap={gap ?? carouselGap}
        items={products}
        useNativeScrollbar={useNativeScrollbar}
        nativeScrollbarClasses={nativeScrollbarClasses}
        progressbar
        renderSlide={(product, index, controls, isSlideVisible) => {
          if (index === 0) {
            carouselControlsRef.current = controls
          }

          return renderSlide(product, index, isSlideVisible)
        }}
        scrollBy={scrollBy}
        onNavigationStateChange={handleNavigationStateChange}
      />
    </section>
  )
}
