import { useEffect, useState } from 'react'
import themeConfig from 'theme'

export const useCarouselGap = (mobileGap: number, desktopGap: number) => {
  const [carouselGap, setCarouselGap] = useState(desktopGap)

  useEffect(() => {
    const getGapSize = (matches: boolean) => (matches ? desktopGap : mobileGap)
    const mediaQuery = window.matchMedia(`(min-width: ${themeConfig.theme.screens.lg})`)

    setCarouselGap(getGapSize(mediaQuery.matches))

    const handler = () => {
      setCarouselGap(getGapSize(mediaQuery.matches))
    }

    mediaQuery.addEventListener('change', handler)
    return () => mediaQuery.removeEventListener('change', handler)
  }, [setCarouselGap, mobileGap, desktopGap])

  return carouselGap
}
