import clsx from 'clsx'
import { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import { CarouselNavigation, CustomNavigationProps } from './CarouselNavigation'
import { CarouselPagination } from './CarouselPagination'
import { CarouselProgressBar } from './CarouselProgressBar'
import { useGetSlidesSetup, CarouselColumnsConfig } from '../hooks/useGetSlidesSetup'
import { CustomProgressBarProps } from '~/modules/StageSliderCarouselModule/components/StageSliderCarouselProgressBar'
import NativeScrollbarStyles from '../NativeScrollbar.module.scss'

interface CarouselProps<T> {
  centerIssuficientSlides?: boolean
  gridConfig?: Partial<CarouselColumnsConfig> | number
  items: (T | null | undefined)[]
  isFullWidth?: boolean
  gap?: number
  navigation?: boolean
  pagination?: boolean
  progressbar?: boolean
  scrollBy?: number | 'group'
  renderBullet?: (props: { bulletIndex: number }) => React.ReactNode
  renderCustomProgressBar?: (props: CustomProgressBarProps) => React.ReactElement | undefined
  renderCustomNavigation?: (props: CustomNavigationProps) => React.ReactElement
  onNavigationStateChange?: (atStart: boolean, atEnd: boolean) => void
  onSlideChangeEnd?: (currentIndex: number) => void
  onNavigationStateStart?: (currentIndex: number) => void
  renderSlide: (
    item: T,
    index: number,
    carouselControls: CarouselControls,
    isSlideVisible: boolean,
  ) => React.ReactElement
  useNativeScrollbar?: boolean
  nativeScrollbarClasses?: string
  progressbarClassName?: string
}

export interface CarouselControls {
  slideToPrev: () => void
  slideToNext: () => void
}

export const Carousel = <T,>({
  centerIssuficientSlides = false,
  gridConfig,
  items,
  isFullWidth = true,
  gap = 0,
  navigation = false,
  pagination = false,
  progressbar = false,
  scrollBy = 'group',
  renderBullet,
  renderCustomProgressBar,
  renderSlide,
  renderCustomNavigation,
  onNavigationStateChange,
  onSlideChangeEnd,
  onNavigationStateStart,
  useNativeScrollbar,
  nativeScrollbarClasses,
  progressbarClassName,
}: CarouselProps<T>) => {
  const { slidesPerView, highestPossibleSpv, cssConfig, scrollBehaviorPreference } =
    useGetSlidesSetup(gridConfig as CarouselColumnsConfig)
  const scrollerRef = useRef<HTMLDivElement | null>(null)
  const observerRef = useRef<IntersectionObserver | null>(null)
  const slidesRefs = useRef<(HTMLDivElement | null)[]>([])
  const [currentIndex, setCurrentIndex] = useState(1)
  const [currentBulletIndex, setCurrentBulletIndex] = useState(1)
  const [isPreviousButtonDisabled, setIsPreviousButtonDisabled] = useState(true)
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true)
  const [slidesRenderedAmount, setSlidesRenderedAmount] = useState(highestPossibleSpv)
  const [isScrolling, setIsScrolling] = useState(false)

  const slides = items.filter((item): item is T => item != null)
  const [progressPercentage, setProgressPercentage] = useState<number>(0)

  const setRefs = (node: HTMLDivElement | null, index: number) => {
    slidesRefs.current[index] = node
  }

  const carouselConfig = {
    ...cssConfig,
    '--carousel-gap': `${gap}px`,
  } as React.CSSProperties

  const slideGroups = useMemo(() => {
    if (!scrollerRef.current || !slides.length) {
      return []
    }

    const numberOfGroups = Math.ceil(slides.length / slidesPerView)

    return Array.from(new Array(numberOfGroups), (_, i) => {
      const groupStartIndex = i * slidesPerView
      const groupEndIndex = groupStartIndex + slidesPerView

      return slides.slice(groupStartIndex, groupEndIndex)
    })
  }, [slidesPerView, slides])

  const handleProgressBarUpdate = useCallback(() => {
    const carousel = scrollerRef.current
    if (!carousel) return

    const internalSlidesPerView = slidesPerView
    const totalSlides = carousel.children.length
    const scrollLeft = carousel.scrollLeft // Amount scrolled
    const slideWidth = carousel.children[0]?.clientWidth || 0
    const totalGapWidth = (totalSlides - 1) * gap // Total gap width
    const totalVisibleWidth = internalSlidesPerView * slideWidth + totalGapWidth // Visible slides + their gaps
    const totalScrollableWidth = carousel.scrollWidth - totalVisibleWidth // Scrollable area

    // Minimum progress based on slidesPerView
    const minProgress = (internalSlidesPerView / totalSlides) * 100
    const scrollProgress = (scrollLeft / totalScrollableWidth) * (100 - minProgress)

    // Final progress ensures the minimum value starts at slidesPerView
    setProgressPercentage(minProgress + scrollProgress)
  }, [gap, slidesPerView])

  const scrollToSlide = useCallback(
    (newIndex: number) => {
      if (scrollerRef.current) {
        const target = scrollerRef.current.children[Math.floor(newIndex) - 1] as HTMLElement

        if (target) {
          scrollerRef.current.scrollTo({
            left: target.offsetLeft,
            behavior: scrollBehaviorPreference,
          })
        }
      }
    },
    [scrollBehaviorPreference],
  )

  const scrollBySlidesAmount = useCallback(
    (direction: 'prev' | 'next') => {
      if (scrollerRef.current && slidesRefs.current.length && scrollBy !== 'group') {
        const firstSlide = slidesRefs.current[0]

        if (!firstSlide) {
          return
        }

        const target = firstSlide.getBoundingClientRect()
        const gap = getComputedStyle(scrollerRef.current)?.getPropertyValue('--carousel-gap') || '0'
        const scrollValue = (target.width + parseInt(gap)) * scrollBy

        scrollerRef.current.scrollBy({
          left: direction === 'prev' ? -scrollValue : scrollValue,
          behavior: scrollBehaviorPreference,
        })
      }
    },
    [scrollBy, scrollBehaviorPreference],
  )

  const scrollToGroup = useCallback(
    (groupIndex: number) => {
      const slideIndex = (groupIndex - 1) * slidesPerView + 1

      scrollToSlide(slideIndex)
    },
    [slidesPerView, scrollToSlide],
  )

  const slideToPrev = useCallback(() => {
    if (scrollBy === 'group') {
      scrollToSlide(currentIndex - slidesPerView > 0 ? currentIndex - slidesPerView : 1)
    } else {
      scrollBySlidesAmount('prev')
    }
  }, [currentIndex, scrollBy, slidesPerView, scrollToSlide, scrollBySlidesAmount])

  const slideToNext = useCallback(() => {
    if (scrollBy === 'group') {
      scrollToSlide(
        currentIndex + slidesPerView < slides.length ? currentIndex + slidesPerView : slides.length,
      )
    } else {
      scrollBySlidesAmount('next')
    }
  }, [currentIndex, scrollBy, slides.length, slidesPerView, scrollToSlide, scrollBySlidesAmount])

  const isSlideVisible = useCallback(
    (index: number) => {
      return (
        slidesRefs.current
          ?.find((_, refIndex) => index === refIndex)
          ?.classList.contains('active') ?? false
      )
    },
    [slidesRefs],
  )

  useEffect(() => {
    if (scrollerRef?.current) {
      observerRef.current = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              const targetIndex = slidesRefs.current.indexOf(entry.target as HTMLDivElement)

              entry.target.classList.add('active')
              setIsPreviousButtonDisabled(isSlideVisible(0))
              setCurrentIndex(targetIndex + 1)

              if (pagination) {
                setCurrentBulletIndex(Math.floor(targetIndex / slidesPerView) + 1)
              }

              if (targetIndex + slidesPerView > slidesRenderedAmount) {
                setSlidesRenderedAmount(targetIndex + slidesPerView)
              }
            } else {
              entry.target.classList.remove('active')
              setIsPreviousButtonDisabled(isSlideVisible(0))
            }
          })
        },
        {
          root: scrollerRef.current.parentNode as HTMLElement,
          threshold: 0.25,
        },
      )

      slidesRefs.current.forEach((ref) => {
        if (ref) {
          observerRef.current?.observe(ref)
        }
      })
    }

    return () => {
      observerRef.current?.disconnect()
    }
  }, [navigation, pagination, slidesPerView, slidesRenderedAmount, items, isSlideVisible])

  useEffect(() => {
    const scroller = scrollerRef.current
    if (!scroller) return
    setIsNextButtonDisabled(
      Math.ceil(scroller.scrollLeft + scroller.clientWidth) >= scroller.scrollWidth,
    )

    if (progressbar) {
      handleProgressBarUpdate()
    }

    const handleScrollStart = () => {
      setIsScrolling(true)
      onNavigationStateStart?.(currentIndex)
      if (progressbar) {
        handleProgressBarUpdate()
      }
    }

    const handleScrollEnd = () => {
      setIsScrolling(false)
      onSlideChangeEnd?.(currentIndex)
      setIsNextButtonDisabled(
        Math.ceil(scroller.scrollLeft + scroller.clientWidth) >= scroller.scrollWidth,
      )
    }

    if ('onscrollend' in window) {
      scroller.addEventListener('scroll', handleScrollStart)
      scroller.addEventListener('scrollend', handleScrollEnd)
    } else {
      // SAFARI POLYFILL, AS CURRENTLY SAFARI DOES NOT SUPPORT SCROLLEND EVENT
      let isScrolling: ReturnType<typeof setTimeout>
      scroller.addEventListener('scroll', () => {
        handleScrollStart()
        clearTimeout(isScrolling)

        isScrolling = setTimeout(() => {
          // mimics the scrollEnd event with debounce
          handleScrollEnd()
        }, 150)
      })
    }

    return () => {
      scroller.removeEventListener('scroll', handleScrollStart)
      scroller.removeEventListener('scrollend', handleScrollEnd)
    }
  }, [
    currentIndex,
    onSlideChangeEnd,
    onNavigationStateStart,
    handleProgressBarUpdate,
    progressbar,
    slidesPerView,
  ])

  const carouselControls: CarouselControls = {
    slideToPrev,
    slideToNext,
  }

  const handleProgressBarClick = useCallback(
    (slideIndex: number) => {
      scrollToSlide(slideIndex)
    },
    [scrollToSlide],
  )

  useEffect(() => {
    if (onNavigationStateChange) {
      onNavigationStateChange(isPreviousButtonDisabled, isNextButtonDisabled)
    }
  }, [
    currentIndex,
    onNavigationStateChange,
    isSlideVisible,
    slidesPerView,
    isPreviousButtonDisabled,
    isNextButtonDisabled,
  ])

  return (
    <div
      data-role="carousel"
      className={`relative grid grid-cols-1 ${
        pagination && slideGroups.length > 1 ? 'grid-rows-[1fr_4rem]' : 'grid-rows-1'
      }`}
      style={carouselConfig}
    >
      <div data-role="carousel-wrapper" className="relative row-start-1">
        {navigation && slideGroups.length > 1 && (
          <CarouselNavigation
            currentIndex={currentIndex}
            isPreviousSlidePossible={isPreviousButtonDisabled}
            isNextSlidePossible={isNextButtonDisabled}
            isFullWidth={isFullWidth}
            renderCustomNavigation={renderCustomNavigation}
            onSlideToPrev={slideToPrev}
            onSlideToNext={slideToNext}
          />
        )}
        <div
          ref={scrollerRef}
          data-role="carousel-slides"
          className={clsx(
            useNativeScrollbar
              ? `${NativeScrollbarStyles.nativeScrollbar} ${nativeScrollbarClasses}`
              : '',
            'relative z-[1] grid snap-x snap-mandatory auto-cols-[calc(100%_/_var(--carousel-slidesPerView)_-_((var(--carousel-slidesPerView)_-_1)_*_var(--carousel-gap))_/_var(--carousel-slidesPerView))] grid-flow-col items-center gap-[var(--carousel-gap)] overflow-x-auto overscroll-x-contain',
            {
              'w-full': isFullWidth,
              '[justify-content:safe_center]':
                centerIssuficientSlides && slidesPerView > slides.length,
            },
          )}
          role="group"
          aria-live="polite"
          style={{
            scrollbarWidth: 'none',
          }}
        >
          {slides.map((item, index) => (
            <div
              key={`slider-${index}`}
              ref={(node) => setRefs(node, index)}
              className="snap-start place-items-start self-stretch"
              aria-label={`${index + 1} / ${slides.length}`}
              aria-roledescription="slide"
            >
              {renderSlide(item, index, carouselControls, isSlideVisible(index))}
            </div>
          ))}
        </div>
      </div>
      {pagination && slideGroups.length > 1 && (
        <CarouselPagination
          groupsCount={slideGroups.length}
          currentIndex={currentBulletIndex}
          onSlideTo={scrollToGroup}
          renderBullet={renderBullet}
        />
      )}
      {progressbar && slideGroups.length > 1 && (
        <CarouselProgressBar
          className={progressbarClassName}
          progressPercentage={progressPercentage}
          disableNavigation={false}
          renderCustomProgressBar={renderCustomProgressBar}
          slidesPerView={slidesPerView}
          totalSlides={slides.length}
          onSlideChange={handleProgressBarClick}
        />
      )}
    </div>
  )
}
